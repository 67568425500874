import { reactive } from 'vue'

export const store = reactive({
    // DATA
    type: '',
    options: [],
    ground: {},
    thicknessisolation: {},
    thicknesschape: {},
    height: {},
    isolation: {},
    chape: {},
    chapeoption: {},
    aditiefexcellfloor: {},
    floorsize: {},
    floorheating: {},
    floorheatingsize: {},
    slope: {},
    slopesize: {},
    info: {
        firstname: '',
        surname: '',
        phone: '',
        email: '',
        street: '',
        number: '',
        bus: '',
        postalcode: '',
        city: '',
        same_siteaddress: 0,
        site_street: '',
        site_number: '',
        site_bus: '',
        site_postalcode: '',
        site_city: '',
        planned_date: '',
        files: [],
        accoustic: 0,
        remark: '',
        privacy: 0,
    },

    // GENERAL
    steps: [
        'TypeStep',
        'InfoStep',
        'ConfirmStep',
    ],
    types: [
        { key: 'nieuwbouw', value: 'Nieuwbouw', icon: 'icon-build' },
        { key: 'renovatie', value: 'Renovatie', icon: 'icon-renovation' },
        //{ key: 'cellulose', value: 'Cellulose', icon: 'icon-isolation' }
    ],
    suboptions: {
        nieuwbouw: [
            { key: 'vloer_kelder', value: 'Vloer van de kelder' },
            { key: 'vloer_gelijkvloers', value: 'Vloer van de gelijkvloerse verdieping' },
            { key: 'vloer_eerste', value: 'Vloer van de eerste verdieping' },
            { key: 'vloer_tweede', value: 'Vloer van de tweede verdieping' },
            { key: 'vloer_derde', value: 'Vloer van de derde verdieping' },
        ],
        renovatie: [
            { key: 'plafond_kruipkelder', value: 'Plafond van de kruipkelder' },
            { key: 'vloer_kelder', value: 'Vloer van de kelder' },
            { key: 'vloer_gelijkvloers', value: 'Vloer van de gelijkvloerse verdieping' },
            { key: 'vloer_eerste', value: 'Vloer van de eerste verdieping' },
            { key: 'vloer_tweede', value: 'Vloer van de tweede verdieping' },
            { key: 'zoldervloer', value: 'Zoldervloer van niet-ingerichte zolderruimte' },
        ],
        // cellulose: [
        //     { key: 'tussenvloeren', value: 'Tussenvloeren' },
        //     { key: 'binnenmuren', value: 'Binnenmuren' },
        //     { key: 'zoldervloer', value: 'Zoldervloer (losblazen)' },
        //     { key: 'dakisolatie', value: 'Dakisolatie' },
        // ],
    }
})